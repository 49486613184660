import React from "react";

import PageWrapper from "../components/PageWrapper";
import { Box } from "../components/Core";
import Store from '../sections/others/Store'

const StorePage = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Box pt={4}>
          <Store /> 
        </Box>
      </PageWrapper>
    </>
  );
};

export default StorePage;