import React from 'react'
import Section from '../../components/Section'
import { Container, Row, Col } from "react-bootstrap";
import { Box, Title, Text } from "../../components/Core";
import { storeItems } from '../../data';
import styled from 'styled-components'

const StoreGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
`
const StoreImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 16px;
`

const Store = () => {
    return (
        <Section>
            <Container fluid>
        <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Title className="my-4">
                    Store
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      
                    `}
                  >Here you can find all the gear we use for work. We only recommend things we personally use and can't live without.<br/> 
                  Check it out!
                  </Text>
                </Box>
              </Col>
            </Row>
            <Row className="mt-5">
              <StoreGrid>
                  {
                    storeItems.map((item) => {
                      return (
                        <a target="_blank" href={item.link}>
                          <StoreImg src={item.image} />
                        </a>
                      )
                    })
                  }
              </StoreGrid>
            </Row>
            </Container>
        </Section>
    )
}

export default Store
